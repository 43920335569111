var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _c(
            "gov-body",
            [
              _vm._v("\n    For organisation\n    "),
              _c("gov-link", {
                attrs: {
                  to: {
                    name: "organisations-show",
                    params: { organisation: _vm.original.id }
                  }
                },
                domProps: { textContent: _vm._s(_vm.original.name) }
              }),
              _vm._v(".\n  ")
            ],
            1
          ),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("From")
                      ]),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("To")
                      ])
                    ],
                    1
                  ),
                  _vm.organisation.hasOwnProperty("url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("URL")]
                          ),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.original.url))
                          ]),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.organisation.url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("name")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Name")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.name))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("slug")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Slug")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.slug))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.slug))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("email")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Email")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.email || "-"))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.email || "-"))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("phone")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Phone")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.phone || "-"))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.phone || "-"))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("description")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Description")]
                          ),
                          _c("gov-table-cell", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.toHtml(_vm.original.description)
                              )
                            }
                          }),
                          _c("gov-table-cell", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.toHtml(_vm.organisation.description)
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("logo_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Logo")]
                          ),
                          _c("gov-table-cell", [
                            _c("img", {
                              staticClass: "ck-logo",
                              attrs: {
                                src: _vm.apiUrl(
                                  "/organisations/" +
                                    _vm.organisation.id +
                                    "/logo.png?v=" +
                                    _vm.requestedAt
                                ),
                                alt: "Organisation logo"
                              }
                            })
                          ]),
                          _c("gov-table-cell", [
                            _c("img", {
                              staticClass: "ck-logo",
                              attrs: {
                                src: _vm.apiUrl(
                                  "/organisations/" +
                                    _vm.organisation.id +
                                    "/logo.png?update_request_id=" +
                                    _vm.updateRequestId
                                ),
                                alt: "Organisation logo"
                              }
                            })
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }