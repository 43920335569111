var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _vm.original
            ? _c(
                "gov-body",
                [
                  _vm._v("\n    For event\n    "),
                  _c("gov-link", {
                    attrs: {
                      to: {
                        name: "events-show",
                        params: { event: _vm.original.id }
                      }
                    },
                    domProps: { textContent: _vm._s(_vm.original.title) }
                  }),
                  _vm._v(".\n  ")
                ],
                1
              )
            : _c("gov-body", [_vm._v(" For a new event. ")]),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _vm.original
                        ? _c(
                            "gov-table-header",
                            { attrs: { scope: "column" } },
                            [_vm._v("From")]
                          )
                        : _vm._e(),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v(_vm._s(_vm.original ? "To" : "New"))
                      ])
                    ],
                    1
                  ),
                  _vm.event.hasOwnProperty("organisation_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Organisation")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty("organisation_id")
                                    ? _c(
                                        "gov-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "organisations-show",
                                              params: {
                                                organisation:
                                                  _vm.original.organisation_id
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.original.organisation.name
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              _c(
                                "gov-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "organisations-show",
                                      params: {
                                        organisation: _vm.event.organisation_id
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.event.organisation.name || ""
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("start_date")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Start date")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("asDate")(_vm.original.start_date)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(_vm._f("asDate")(_vm.event.start_date))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("end_date")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("End date")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("asDate")(_vm.original.end_date)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm._f("asDate")(_vm.event.end_date)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("start_time")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Start time")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.start_time
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.start_time))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("end_time")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("End time")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.end_time
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.end_time))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("title")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Title")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.title)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.title))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("intro")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Intro")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.intro)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.intro))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("description")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Description")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.toHtml(_vm.original.description)
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("gov-table-cell", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.toHtml(_vm.event.description)
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("is_free")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Is free")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("yesNo")(_vm.original.is_free))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm._f("yesNo")(_vm.event.is_free)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("fees_url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Fees URL")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.fees_url
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.event.fees_url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("fees_text")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Fees text")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.fees_text
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.fees_text))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("organiser_name")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Organiser name")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.organiser_name
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.organiser_name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("organiser_email")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Organiser email")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.organiser_email))
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.organiser_email))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("organiser_phone")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Organiser phone")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.organiser_phone
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.organiser_phone))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("organiser_url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Organiser url")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.organiser_url
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.organiser_url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("booking_title")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Booking title")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.booking_title
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.booking_title))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("booking_summary")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Booking summary")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.booking_summary
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.booking_summary))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("booking_url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Booking url")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.booking_url
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.booking_url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("booking_cta")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Booking call to action")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(
                                      _vm.original.booking_cta
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.event.booking_cta))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("homepage")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Homepage")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("yesNo")(_vm.original.homepage))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm._f("yesNo")(_vm.event.homepage)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("is_virtual")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Is virtual")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("yesNo")(_vm.original.is_virtual)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(_vm._f("yesNo")(_vm.event.is_virtual))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("category_taxonomies")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Category taxonomies")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty(
                                    "category_taxonomies"
                                  ) &&
                                  Array.isArray(
                                    _vm.original.category_taxonomies
                                  )
                                    ? _c(
                                        "gov-list",
                                        { attrs: { bullet: "" } },
                                        _vm._l(
                                          _vm.original.category_taxonomies,
                                          function(taxonomy, index) {
                                            return _c(
                                              "li",
                                              {
                                                key:
                                                  "category_taxonomies." + index
                                              },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.taxonomyName(
                                                        _vm.findTaxonomy(
                                                          taxonomy.id
                                                        )
                                                      )
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              Array.isArray(_vm.event.category_taxonomies)
                                ? _c(
                                    "gov-list",
                                    { attrs: { bullet: "" } },
                                    _vm._l(
                                      _vm.event.category_taxonomies,
                                      function(taxonomy, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: "category_taxonomies." + index
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.taxonomyName(
                                                    _vm.findTaxonomy(taxonomy)
                                                  )
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("location_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Location")]
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.originalLocation
                                ? _c("location-details", {
                                    attrs: { location: _vm.originalLocation }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.eventLocation
                                ? _c("location-details", {
                                    attrs: { location: _vm.eventLocation }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.event.hasOwnProperty("image_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Image")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/organisation-events/" +
                                        _vm.event.id +
                                        "/image.png?v=" +
                                        _vm.requestedAt
                                    ),
                                    alt: "Event image"
                                  }
                                })
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm.event.id
                              ? _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/organisation-events/" +
                                        _vm.event.id +
                                        "/image.png?update_request_id=" +
                                        _vm.updateRequestId
                                    ),
                                    alt: "Event image"
                                  }
                                })
                              : _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/organisation-events/new/image.png?update_request_id=" +
                                        _vm.updateRequestId
                                    ),
                                    alt: "Event image"
                                  }
                                })
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }