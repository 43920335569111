var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _vm.original
            ? _c(
                "gov-body",
                [
                  _vm._v("\n    For page\n    "),
                  _c("gov-link", {
                    attrs: {
                      to: {
                        name: "pages-edit",
                        params: { page: _vm.original.id }
                      }
                    },
                    domProps: { textContent: _vm._s(_vm.original.title) }
                  }),
                  _vm._v(".\n  ")
                ],
                1
              )
            : _vm._e(),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _vm.original
                        ? _c(
                            "gov-table-header",
                            { attrs: { scope: "column" } },
                            [_vm._v("From")]
                          )
                        : _vm._e(),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v(_vm._s(_vm.original ? "To" : "New"))
                      ])
                    ],
                    1
                  ),
                  _vm.page.hasOwnProperty("page_type")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Type")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("ucfirst")(
                                      _vm._f("originalExists")(
                                        _vm.original.page_type
                                      )
                                    )
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(
                              _vm._s(_vm._f("ucfirst")(_vm.page.page_type))
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("title")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Title")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.title)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [_vm._v(_vm._s(_vm.page.title))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("slug")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Slug")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("originalExists")(_vm.original.slug)
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [_vm._v(_vm._s(_vm.page.slug))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("enabled")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Status")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(
                                  _vm._s(_vm._f("status")(_vm.original.enabled))
                                )
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm._f("status")(_vm.page.enabled)))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("order")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Order")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.order))
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [_vm._v(_vm._s(_vm.page.order))])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("content")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Content")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty("content") &&
                                  typeof _vm.original.content === "object" &&
                                  _vm.original.content.constructor === Object
                                    ? _c(
                                        "div",
                                        [
                                          _c("page-content", {
                                            attrs: {
                                              content: _vm.original.content
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.page.hasOwnProperty("content") &&
                              typeof _vm.page.content === "object" &&
                              _vm.page.content.constructor === Object
                                ? _c(
                                    "div",
                                    [
                                      _c("page-content", {
                                        attrs: { content: _vm.page.content }
                                      })
                                    ],
                                    1
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("image_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Image")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/pages/" +
                                        _vm.page.id +
                                        "/image.png?v=" +
                                        _vm.requestedAt
                                    ),
                                    alt: "Page image"
                                  }
                                })
                              ])
                            : _vm._e(),
                          _c("gov-table-cell", [
                            _vm.page.id
                              ? _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/pages/" +
                                        _vm.page.id +
                                        "/image.png?update_request_id=" +
                                        _vm.updateRequestId
                                    ),
                                    alt: "Page image"
                                  }
                                })
                              : _c("img", {
                                  staticClass: "ck-logo",
                                  attrs: {
                                    src: _vm.apiUrl(
                                      "/pages/new/image.png?update_request_id=" +
                                        _vm.updateRequestId
                                    ),
                                    alt: "Page image"
                                  }
                                })
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.page.hasOwnProperty("collections")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Collections")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.hasOwnProperty(
                                    "collection_categories"
                                  ) &&
                                  Array.isArray(
                                    _vm.original.collection_categories
                                  )
                                    ? _c(
                                        "gov-list",
                                        { attrs: { bullet: "" } },
                                        _vm._l(
                                          _vm.original.collection_categories,
                                          function(collection, index) {
                                            return _c(
                                              "li",
                                              { key: "collections." + index },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(collection.name) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            [
                              Array.isArray(_vm.page.collections)
                                ? _c(
                                    "gov-list",
                                    { attrs: { bullet: "" } },
                                    _vm._l(_vm.page.collections, function(
                                      collectionId,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        { key: "collections." + index },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.collectionName(
                                                  _vm.findCollection(
                                                    collectionId
                                                  )
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }