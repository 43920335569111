import { render, staticRenderFns } from "./Show.vue?vue&type=template&id=2ec4b99e&"
import script from "./Show.vue?vue&type=script&lang=js&"
export * from "./Show.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/travis/build/AyupDigital/hf-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ec4b99e')) {
      api.createRecord('2ec4b99e', component.options)
    } else {
      api.reload('2ec4b99e', component.options)
    }
    module.hot.accept("./Show.vue?vue&type=template&id=2ec4b99e&", function () {
      api.rerender('2ec4b99e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/update-requests/Show.vue"
export default component.exports