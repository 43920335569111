var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _c(
            "gov-body",
            [
              _vm._v("\n    For location\n    "),
              _c("gov-link", {
                attrs: {
                  to: {
                    name: "locations-show",
                    params: { location: _vm.original.id }
                  }
                },
                domProps: { textContent: _vm._s(_vm.original.address_line_1) }
              }),
              _vm._v(".\n  ")
            ],
            1
          ),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("From")
                      ]),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("To")
                      ])
                    ],
                    1
                  ),
                  _vm.location.hasOwnProperty("address_line_1")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Address line 1")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.address_line_1))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.address_line_1))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("address_line_2")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Address line 2")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.address_line_2))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.address_line_2))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("address_line_3")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Address line 3")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.address_line_3))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.address_line_3))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("city")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("City")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.city))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.city))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("county")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("County")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.county))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.county))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("postcode")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Postcode")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.postcode))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.postcode))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("country")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Country")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.country))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.country))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("accessibility_info")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Accessibility info")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.accessibility_info))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.location.accessibility_info))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("has_induction_loop")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Has induction loop")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm.original.has_induction_loop ? "Yes" : "No"
                              )
                            )
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm.location.has_induction_loop ? "Yes" : "No"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("has_wheelchair_access")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Has wheelchair access")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm.original.has_wheelchair_access
                                  ? "Yes"
                                  : "No"
                              )
                            )
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(
                              _vm._s(
                                _vm.location.has_wheelchair_access
                                  ? "Yes"
                                  : "No"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.location.hasOwnProperty("image_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Image")]
                          ),
                          _c("gov-table-cell", [
                            _c("img", {
                              staticClass: "ck-logo",
                              attrs: {
                                src: _vm.apiUrl(
                                  "/locations/" +
                                    _vm.location.id +
                                    "/image.png?v=" +
                                    _vm.requestedAt
                                ),
                                alt: "Location image"
                              }
                            })
                          ]),
                          _c("gov-table-cell", [
                            _c("img", {
                              staticClass: "ck-logo",
                              attrs: {
                                src: _vm.apiUrl(
                                  "/locations/" +
                                    _vm.location.id +
                                    "/image.png?update_request_id=" +
                                    _vm.updateRequestId
                                ),
                                alt: "Location image"
                              }
                            })
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }